module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Interval Timer","short_name":"Interval Timer","start_url":"/","background_color":"#1c1c1e","theme_color":"#2c2c2e","display":"standalone","icon":"./static/logo.svg","categories":["fitness"],"screenshots":[{"src":"./static/screenshots/1.webp","sizes":"360x640","type":"image/webp"},{"src":"./static/screenshots/2.webp","sizes":"360x640","type":"image/webp"},{"src":"./static/screenshots/3.webp","sizes":"360x640","type":"image/webp"},{"src":"./static/screenshots/4.webp","sizes":"360x640","type":"image/webp"},{"src":"./static/screenshots/5.webp","sizes":"360x640","type":"image/webp"},{"src":"./static/screenshots/6.webp","sizes":"360x640","type":"image/webp"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"576fbff99428b48e27a98dfc70c57686"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
